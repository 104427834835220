/*
 * @Description: 页面监控
 */
import type Aegis from 'aegis-web-sdk';
import { perfAppStart } from '@/services/performance/lifecycles';
import MonitorSDK from '@suite/shared/src/monitor-sdk';
import { SDKType } from '@suite/shared/src/monitor-sdk/types/';

function initRumMonitor(): void {
  const monitor = new MonitorSDK(SDKType.tam, {
    id: window.AppConfig.aegisMoniterId,
    env: process.env.VUE_APP_NODE_ENV,
    aid: false,
  });
  if (!monitor) return;
  monitor.loadSDK().then((agesInstance) => {
    window.aegisSDK = agesInstance as Aegis;
    // 从 url 上获取子应用名
    const appName = location.pathname.split('/')[1] as MicroApp.AppKeyType | undefined;
    perfAppStart(appName);
  });
}
function initTamV2Monitor(): void {
  const url = 'https://galileotelemetry.tencent.com/collect'; // 伽利略上报地址，不变
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!window.AegisV2) return;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.aegisV2 = new window.AegisV2({
    id: process.env.VUE_APP_TAM_V2_ID, // 项目ID，即上报id
    hostUrl: { url },
    plugin: {
      pv: true, // 默认开启pv收集器
      aid: false, // 默认开启aid收集器
      error: true, // 默认开启error收集器
      device: true, // 默认开启device收集器
      close: true, // 默认开启close收集器
      pagePerformance: true, // 默认开启pagePerformance收集器
      webVitals: true, // 默认开启webVitals收集器
      session: true, // 默认开启
      reportApiSpeed: true, // 默认开启接口测速
      reportAssetSpeed: true, // 默认开启资源测速

      fingerId: true, // 默认关闭fingerId
      ie: true, // 默认关闭ie收集器
      spa: true, // 默认关闭spa收集器
      api: true, // 默认关闭api收集器
      apiSpeed: true, // 默认关闭apiSpeed收集器
      assetSpeed: true, // 默认关闭assetSpeed收集器
    },
  });
}
// 配置了 RUM 监控上报 ID 才加载资源
if (process.env.VUE_APP_NODE_ENV === 'production' && window.AppConfig.aegisMoniterId) {
  try {
    initRumMonitor();
  } catch (e) {
    console.log('初始化sdk异常', e);
  }
}

// 配置了 Tam V2 ID 才加载资源
if (window.AppConfig.tamV2Id) {
  try {
    initTamV2Monitor();
  } catch (e) {
    console.log('初始化sdk异常', e);
  }
}

