/**
 * @description Service Worker
 * @module types
 */

import { Workbox } from 'workbox-window';

// 这里不使用 utils 内的 isMobile 是为了快速执行，entry 内不应该依赖其它模块
const isMobile = function isMobile(): boolean {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent);
};

/**
 * Sevice Worker 消息-前端配置
 */
const MESSAGE_FRONT_CONFIG_TYPE = 'FrontConfig';

/**
 * 注销 Service Worker
 */
const MESSAGE_UNREGISTER_TYPE = 'Unregister';

/**
 * 清除 Service Worker
 */
async function clearServiceWorker(): Promise<void> {
  // 关闭 servive worker 后要清除页面缓存
  const registrations = await navigator.serviceWorker.getRegistrations();
  if (!registrations.length) return;
  // 获取注册的 service worker，确定影响的范围是在 / 路径下
  const beaconWb: ServiceWorkerRegistration | undefined = registrations.find(item => item.scope === `${location.origin}/`);
  if (!beaconWb) return;
  beaconWb.active?.postMessage({
    type: MESSAGE_UNREGISTER_TYPE,
  });
}

async function initServiceWorker(): Promise<void> {
  if (!('serviceWorker' in navigator)) return;
  try {
    const useNodeStatic = Boolean(location.search.includes('beacon-use-static-strategy')
    || window.localStorage.getItem('__BEACON_GLOBAL_USE_STATIC__'));
    if (useNodeStatic) {
      // URL 包含 beacon-use-static-strategy 表示使用 node 服务内的静态资源，
      // 这里一定要停用并清除 service worker 内的缓存
      clearServiceWorker();
      return;
    }
    // 每次都使用最新的配置
    const frontConfig: FrontConfigType = await window.SuitePreFetch.getFrontendCommonConfig;

    let enableSW = false;
    if (frontConfig.serviceWorker) {
      const { enablePC, enableMobile } = frontConfig.serviceWorker;
      enableSW = isMobile() ? enableMobile : enablePC;
    }

    if (enableSW) {
      const beaconWb = new Workbox('/service-worker.js');
      beaconWb.register();
      beaconWb.messageSW({
        type: MESSAGE_FRONT_CONFIG_TYPE,
        payload: frontConfig,
      });
    } else {
      clearServiceWorker();
    }
  } catch (err) {
    console.error('初始化 Service Worker 报错', err);
  }
}

initServiceWorker();
