/*
 * @Author: kelvinywang
 * @Date: 2022-06-05 20:36:19
 * @LastEditors: kelvinywang
 * @LastEditTime: 2022-06-15 21:10:46
 * @Description: 处理移动端路由，当前应用不存在移动端直接跳转
 */

const goMobileConsole = function goMobileConsole(): void {
  window.location.href = window.AppConfig.mobileHost as string;
};

// 这里不使用 utils 内的 isMobile 是为了快速执行，entry 内不应该依赖其它模块
const isMobile = function isMobile(): boolean {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent);
};

/**
 * 处理移动端路由
 */
const handleMobileRoute = async function handleMobileRoute(): Promise<void> {
  const { pathname } = window.location;
  const currentApp = window.FrontConfig.microApps.find(app => (
    app.baseroute
      ? pathname.startsWith(app.baseroute)
      : false
  ));
  if (!currentApp || !currentApp.mobile) {
    // 当应用不支持移动端时，直接跳转到移动端控制台页面
    // 等到配置更新完后再跳转页面
    await window.SuitePreFetch?.getFrontendCommonConfig;
    goMobileConsole();
    return;
  }

  if (typeof currentApp.mobile === 'object'
    && currentApp.mobile.match
    && new RegExp(currentApp.mobile.match).test(pathname)) {
    // 匹配到路径直接进入套件
    return;
  }

  const mobilePathTmp = location.href.replace(location.origin, '').replace(currentApp.baseroute || '', '');
  const mobilePath = mobilePathTmp.startsWith('/') ? mobilePathTmp.substring(1) : mobilePathTmp;
  await window.SuitePreFetch?.getFrontendCommonConfig;
  // TODO: 支持移动端的子应用暂时跳转到各自 inner 域名，套件下 fetch 请求有延时需要排查
  // 等到配置更新完后再跳转页面
  location.href = `${currentApp.url}${mobilePath}`;
  return;
};

const initMobileRoute = function initMobileRoute(): void {
  // 非移动端 或者 当前版本不存在移动端 直接返回
  if (!isMobile() || !window.AppConfig?.mobileHost) return;
  // FrontConfig 在 index.html 前置请求，不阻塞 JS 资源加载
  // 由于 FrontConfig 中存在路由配置，需要等到配置返回再初始化 Vue
  if (window.FrontConfig) {
    handleMobileRoute();
  } else {
    window.SuitePreFetch?.getFrontendCommonConfig?.then(() => {
      handleMobileRoute();
    });
  }
};

initMobileRoute();
