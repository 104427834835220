/**
 * @description 初始化插件 SDK，并且监听相关事件做上报
 */
import type { EmptyPluginSourceEventCallback, HttpErrorEventCallback } from '@tencent/beacon-plugin';

// 插件 SDK 前缀，用于上报识别
const BEACON_PLUGIN_PREFIX = 'BeaconPluginSDK';

/**
 * 插件 SDK 事件对象转为 jsonstring
 * @param payload
 * @returns
 */
const payload2String = function payload2String(payload: any): string {
  let result = '';
  try {
    // aegisSDK 参数要string类型才能上报成功，这里做下转换
    result = `${BEACON_PLUGIN_PREFIX}:${JSON.stringify(payload)}`;
  } catch (e) {
    result = `${BEACON_PLUGIN_PREFIX}: stringify failure : ${e}`;
  }
  return result;
};

/**
 * aegisSDK 上报
 * @param code 插件 SDK 上报事件 code
 * @param payload
 */
const aegisSDKErrorReport = function aegisSDKErrorReport(code: string, payload: any): void {
  // aegisSDK 参数要string类型才能上报成功，这里做下转换
  const msg = payload2String(payload);
  // 基座前端配置，用于辅助排查问题
  const frontConfigString = payload2String(window.FrontConfig);
  window.aegisSDK?.error({
    msg,
    // 设置插件 SDK 前缀
    ext1: `${BEACON_PLUGIN_PREFIX}:${code}`,
    // 基座前端配置
    ext2: frontConfigString,
  });

  window.aegisV2?.error({
    msg,
    // 设置插件 SDK 前缀
    ext1: `${BEACON_PLUGIN_PREFIX}:${code}`,
    // 基座前端配置
    ext2: frontConfigString,
  });
};


// 插件 SDK 初始化
window.BeaconPlugin?.init();

// 监听加载到空插件资源的插件事件
window.BeaconPlugin?.on('emptyPluginSource', (payload: EmptyPluginSourceEventCallback) => {
  aegisSDKErrorReport('emptyPluginSource', payload);
});

// 监听SDK http错误事件
window.BeaconPlugin?.on('httpError', (payload: HttpErrorEventCallback) => {
  aegisSDKErrorReport('httpError', payload);
});
