/*
 * @Description: 获取前端配置
 */
const BDS_FRONTCONFIG_KEY = 'BDS_FRONTCONFIG';
const FORCE_RESET_KEY_LIST = ['otherApp', 'sidebarAllAppList', '/wetable'];

/**
 * 从 locastorage 内获取配置
 * @returns
 */
const getFrontConfigByStorage = function getFrontConfigByStorage(): FrontConfigType | undefined {
  try {
    // 私有化版本不缓存
    if (window.AppConfig.releaseVersion === 'private') return;
    const tmpConfigStr = localStorage.getItem(BDS_FRONTCONFIG_KEY);
    if (!tmpConfigStr) return;
    // 存在强制清除的 key，直接返回
    if (!FORCE_RESET_KEY_LIST.every(key => tmpConfigStr.includes(key))) return;
    return JSON.parse(tmpConfigStr);
  } catch (err) {
    console.info('未找到本地 FrontConfig 配置:', err);
    return;
  }
};

/**
 * 设置配置到 locastorage
 * @param frontConfigData
 * @returns
 */
const setFrontConfigToStorage = function setFrontConfigToStorage(frontConfigData: FrontConfigType): void {
  try {
    localStorage.setItem(BDS_FRONTCONFIG_KEY, JSON.stringify(frontConfigData));
  } catch (err) {
    console.error('设置 FrontendCommonConfig 异常:', err);
  }
};

/**
 * 处理 FrontConfig 结构
 * @param data {FrontConfigType}
 * @returns
 */
const patchFrontConfig = function patchFrontConfig(data: FrontConfigType): void {
  if (!data.microApps) return;
  data.microApps.some((app) => {
    if (!app.url) return false;
    // 当 url 为 http | https | // 开头 不需要处理默认域名
    if (/^(http|https|\/\/)/.test(app.url)) return false;
    // 当 url 存在时，若没有设置域名默认为当前域名
    const urlInstance = new URL(app.url, location.origin);
    Object.assign(app, {
      url: urlInstance.href,
    });
    return false;
  });
};

// 1. 优先获取 localstorage 内的配置
const frontConfig = getFrontConfigByStorage();
if (frontConfig) {
  window.FrontConfig = frontConfig;
}
window.SuitePreFetch = {};
// 使用异步缓存请求
window.SuitePreFetch.getFrontendCommonConfig = fetch('/api/suiteserver/web/commonConfig/getFrontendCommonConfig')
  .then(response => response.json())
  .then((res: {
    code: number,
    data?: FrontConfigType
  }) => {
    if (res.code !== 0 || !res.data) return;
    const frontConfig = res.data;
    patchFrontConfig(frontConfig);
    setFrontConfigToStorage(frontConfig);
    // 2. 当优先获取本地配置生效时，重置完本地配置后直接返回
    if (window.FrontConfig) return frontConfig;
    // 3. 当优先获取本地配置未生效时，需要设置 window.FrontConfig 并发送事件
    window.FrontConfig = frontConfig;
    return frontConfig;
  });
// 在私有化环境下，后端配置获取 appConfig 则覆盖版本配置
if (frontConfig?.appConfig) {
  window.AppConfig = frontConfig.appConfig;
}
