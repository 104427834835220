/*
 * @Description: 页面监控
 */
/**
 * 路由替换
 * redirectRegexMap 格式：
 * {
 *   // 匹配正则
 *  .*\/datainsight\/.*\/report\/.*: {
 *    // 替换目标
 *    target: datainsight
 *    // 替换物
 *    replacement: xingpan
 *   }
 * }
 */
const redirect = function redirect(): void {
  const { pathname, search } = window.location;
  const { redirectRegexMap = {} } = window.FrontConfig;
  Object.keys(redirectRegexMap).forEach((regex: string) => {
    if (new RegExp(regex, 'g').test(pathname)) {
      const { target, replacement } = redirectRegexMap[regex];
      window.location.href = pathname.replace(target, replacement) + search;
    }
  });
};

/**
 * 存量页面迁移后重定向
 */
const redirectRoute = function redirectRoute(): void {
  // FrontConfig 在 index.html 前置请求，不阻塞 JS 资源加载
  // 由于 FrontConfig 中存在路由配置，需要等到配置返回再初始化 Vue
  if (window.FrontConfig) {
    redirect();
  } else {
    window.SuitePreFetch?.getFrontendCommonConfig?.then(() => {
      redirect();
    });
  }
};

redirectRoute();
